<template>
    <div :class="`user-dropdown-${theme}`">
      <div :class="`user-button-${theme}`">
        <span :id="`userName-${theme}`" class="d-none d-sm-block userName">{{ user.name }}</span>
        <div :class="`user-dropdown-img-${theme}`">
          <Gravatar :email="user.email" alt="user" />
        </div>
        <i class="fa fa-angle-down"></i>
      </div>
      <div :class="`user-dropdown-content-${theme}`">
        <router-link to="/admin" v-if="user.admin">
          <i class="fa fa-cogs"></i> Administração
        </router-link>
        <router-link to="/logScreen" v-if="user.admin">
          <i class="fa fa-th-list"></i> Auditoria
        </router-link>
        <a v-b-modal.modal-about> <i class="fa fa-info-circle"></i> Sobre </a>
        <a href @click.prevent="logout"> <i class="fa fa-sign-out"></i> Sair </a>
  
        <b-modal id="modal-about" title="Informações do Sistema" ok-only>
          <p class="my-4">Versão 1.0.333</p>
        </b-modal>
      </div>
    </div>
  </template>
  
  <script>
  import { userKey } from "../../global";
  import { mapState } from "vuex";
  import Gravatar from "vue-gravatar";
  // import axios from "axios";
  // import { baseApiUrl, showError } from "@/global";
  
  export default {
    name: "UserDropdown",
    data: function () {
      return {
        theme: null
      }
    },
    components: { Gravatar },
    computed: mapState(["user"]),
    methods: {
      logout() {
        this.$store.commit("setUser", null)
  
        if (JSON.parse(localStorage.getItem(userKey)).isPatient) {
          localStorage.removeItem(userKey)
          this.$router.push({ name: "authPatient" })
        } else {
          localStorage.removeItem(userKey)
          this.$router.push({ name: "auth" })
        }
      },
    },
    mounted() {
      this.theme = localStorage.getItem('theme')
    }
  };
  </script>
  
  <style>
  .userName-green {
    align-self: center;
    align-items: center;
    display: relative;
    justify-items: center;
    justify-self: center;
    justify-content: center;
    color: rgb(0, 88, 62);
    font-weight: bold;
  }
  
  .userName-wine {
    align-self: center;
    align-items: center;
    display: relative;
    justify-items: center;
    justify-self: center;
    justify-content: center;
    color: #B79472;
    font-weight: bold;
  }
  
  .userName-violet {
    align-self: center;
    align-items: center;
    display: relative;
    justify-items: center;
    justify-self: center;
    justify-content: center;
    color: #6E4DA4;
    font-weight: bold;
  }
  
  .user-dropdown {
    position: relative;
    height: 100%;
  }
  
  .user-button-green {
    display: flex;
    align-items: center;
    color: rgb(0, 88, 62);
    font-weight: 100;
    height: 100%;
    padding: 0px 20px 0px 20px;
  }
  
  .user-button-wine {
    display: flex;
    align-items: center;
    color: rgb(248, 201, 201);
    font-weight: 100;
    height: 100%;
    padding: 0px 20px 0px 20px;
  }
  
  .user-button-violet {
    display: flex;
    align-items: center;
    color: #d8cceb;
    font-weight: 100;
    height: 100%;
    padding: 0px 20px 0px 20px;
  }
  
  .user-dropdown:hover {
    background-color: rgba(0, 88, 62, 0.2);
    border-radius: 10pt;
  }
  
  .user-dropdown-img-green {
    margin: 0px 10px;
  }
  
  .user-dropdown-img-wine {
    margin: 0px 10px;
  }
  
  .user-dropdown-img-violet {
    margin: 0px 10px;
  }
  
  .user-dropdown-img-green>img {
    max-height: 30px;
    border-radius: 5px;
  }
  
  .user-dropdown-img-wine>img {
    max-height: 30px;
    border-radius: 5px;
  }
  
  .user-dropdown-img-violet>img {
    max-height: 30px;
    border-radius: 5px;
  }
  
  .user-dropdown-content-green {
    position: absolute;
    right: 0px;
    background-color: #dee9dc;
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
  
  .user-dropdown-content-wine {
    position: absolute;
    right: 0px;
    background-color: rgb(248, 201, 201);
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
  
  .user-dropdown-content-violet {
    position: absolute;
    right: 0px;
    background-color: #f3eaff;
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
  
  .user-dropdown-green:hover .user-dropdown-content-green {
    visibility: visible;
    opacity: 1;
  }
  
  .user-dropdown-wine:hover .user-dropdown-content-wine {
    visibility: visible;
    opacity: 1;
  }
  
  .user-dropdown-violet:hover .user-dropdown-content-violet {
    visibility: visible;
    opacity: 1;
  }
  
  .user-dropdown-content-green a {
    text-decoration: none;
    color: #000;
    padding: 10px;
  }
  
  .user-dropdown-content-wine a {
    text-decoration: none;
    color: #000;
    padding: 10px;
  }
  
  .user-dropdown-content-violet a {
    text-decoration: none;
    color: #000;
    padding: 10px;
  }
  
  .user-dropdown-content-green a:hover {
    text-decoration: none;
    color: #000;
    background-color: #ebf7e9;
  }
  
  .user-dropdown-content-wine a:hover {
    text-decoration: none;
    color: #000;
    background-color: #e4baba;
  }
  
  .user-dropdown-content-violet a:hover {
    text-decoration: none;
    color: #000;
    background-color: #f3eaff;
  }
  </style>